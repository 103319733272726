import {put, call,takeLatest} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import TransferService from "../../api/services/front/transfer.service";
const _transferService = new TransferService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* _cumulTransactionListSaga(payload_data) {

    const {payload} = payload_data;
    const {page} = payload_data;

    try {

        let transfer_list= `cumul/transaction/${payload.id_wallet}/?page=${page}`;

        if (payload.status) {
            transfer_list += `&status=${payload.status}`
        }

        if (payload.date_debut) {
            transfer_list += `&date_debut=${payload.date_debut}`
        }

        if (payload.date_fin) {
            transfer_list += `&date_fin=${payload.date_fin}`
        }

        const response = yield call(_transferService._getTransfert,transfer_list);
        yield put(Actions.cumulTransactionListSuccess(response?.data));
    } catch (error) {
        yield put(Actions.cumulTransactionListFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}

export default function* cumulTransactionListSaga() {
    yield takeLatest(constants.CUMUL_TRANSACTION_LIST_REQUESTING, _cumulTransactionListSaga);
}
