import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import CardService from "../../api/services/front/card.service";
import {blockedOrUndblockedCardFailed, blockedOrUndblockedCardSuccess} from "./actions";
const _cardService= new CardService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createBloackedOrdUnblockedCardSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_cardService.blockedOrUnblockeCard, payload,payload.id);
        yield put(blockedOrUndblockedCardSuccess(response?.data));
        yield call(Notify.success,`Votre carte a été ${payload.operation==="B"?"Bloqué":"Débloquer"}ajouté avec succès`)
        yield put(hideModal());
    } catch (error) {
        yield put(blockedOrUndblockedCardFailed(error));
        yield call(Notify.failure,error.message)
    }

}


export default function* bloackedOrdUnblockedCardSagaSaga() {
    yield takeEvery(Actions.BLOCKED_OR_UNBLOCKED_CARD_REQUESTING, createBloackedOrdUnblockedCardSaga);
}
