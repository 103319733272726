import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import {initAdminPasswordFailed, initAdminPasswordSuccess} from "./actions";
import * as navigations  from "../../constants/navigation";
import AuthService from "../../api/services/front/auth.service";

const _authService= new AuthService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createInitAdminPasswordSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_authService.initAdminPassword, payload);
        yield put(initAdminPasswordSuccess(response?.data));
        yield call(Notify.success,"Votre mot de passe a été initialisé avec succès")
        yield put(hideModal());
        payload.redirectTo(navigations.LOGIN)
        payload.resetForm()
    } catch (error) {
        yield put(initAdminPasswordFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* initAdminPasswordSaga() {
    yield takeEvery(Actions.INIT_ADMIN_PASSWORD_REQUESTING, createInitAdminPasswordSaga);
}
