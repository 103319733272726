import ApiService from "./api.service";

const adminEndpoint={
    _url_blocked: 'mastercard/operation_card',
    _url_order_action:'status/command'
}

/**
 * Api de configuration
 * @type {ApiService}
 */
const apiUrl= new ApiService()
class CardService {


    blockedOrUnblockeCard(data,id_card){
        return apiUrl._put(adminEndpoint._url_blocked+`/${id_card}`,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }


    makeOrderPhysicalCardAction(data,id_card){
        return apiUrl._put(adminEndpoint._url_order_action+`/${id_card}`,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

}

export default CardService
