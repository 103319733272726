import {put, call,takeLatest} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import AdminService from "../../api/services/front/admin.service";
const _adminService = new AdminService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* _onboardUserSaga(payload_data) {

    const {payload} = payload_data;
    const {page} = payload_data;

    try {

        let transfer_list= `list/user-agent/${payload.user_id}/?page=${page}`;

        if (payload.telephone) {
            transfer_list += `&telephone=${payload.telephone}`
        }

        if (payload.period) {
            transfer_list += `&filter_period=${payload.period}`
        }

        if (payload.is_active) {
            transfer_list += `&is_active=${payload.is_active}`
        }

        if (payload.date_debut) {
            transfer_list += `&date_debut=${payload.date_debut}`
        }

        if (payload.date_fin) {
            transfer_list += `&date_fin=${payload.date_fin}`
        }

        const response = yield call(_adminService._getData,transfer_list);
        yield put(Actions.onboardUserSuccess(response?.data));
    } catch (error) {
        yield put(Actions.onboardUserFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}

export default function* onboardUserSaga() {
    yield takeLatest(constants.ONBOARD_USER_REQUESTING, _onboardUserSaga);
}
