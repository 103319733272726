import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import AdminService from "../../api/services/front/admin.service";
const _adminService = new AdminService()

/**
 *
 * @returns {Generator<*, void, *>}
 * @private
 */

function* _settingsAccountLogsListSaga(payload_data) {
    const {payload} = payload_data;

    try {
        let url= `get/log/admin?page=${payload}`;

        const response = yield call(_adminService._getData,url);
        yield put(Actions.settingsAccountLogsListSuccess(response?.data));
    } catch (error) {
        yield put(Actions.settingsAccountLogsListFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}


export default function* settingsAccountLogsListSaga() {
    yield takeEvery(constants.SETTINGS_ACCOUNT_LOGS_LIST_REQUESTING, _settingsAccountLogsListSaga);
}
