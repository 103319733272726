import ApiService from "./api.service";

const approEndpoint={
    appro_list: 'appro/list',
    appro_init: 'appro/init',
    appro_valiate: 'appro/validate'
}

/**
 * Api de configuration
 * @type {ApiService}
 */
const apiUrl= new ApiService()
class ApproService {

    /**
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createInitSupply(data){
        return apiUrl._post(approEndpoint.appro_init,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     * @private
     */
    _getApproList(url){
        return apiUrl._get(url).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    validateSupply(data,id_initialisation){
        return apiUrl._put(approEndpoint.appro_valiate+`/${id_initialisation}`,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

}

export default ApproService
