import * as constants from "./constants";

export const  settingsCommissionListRequest =(payload)=> {
  return { type: constants.SETTINGS_COMMISSION_LIST_REQUESTING,payload}
}

export const settingsCommissionListSuccess=(payload)=>{
  return { type:constants.SETTINGS_COMMISSION_LIST_SUCCESS, payload }
}

export const settingsCommissionListFailed=(error)=>{
  return { type:constants.SETTINGS_COMMISSION_LIST_FAILED, error }
}
