import React, { lazy, Suspense } from "react";
import * as navigations from "./constants/navigation";
import { getLocalState } from "./helper/localStorage";
import { InLineLoader } from "./components/InlineLoader";
import { Route, Switch, Redirect } from "react-router-dom";
const Login = lazy(() => import("./pages/Login"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const InitAdminPassword = lazy(() => import("./pages/InitAdminPassword"));
const NotFound = lazy(() => import("./pages/NotFound"));
const AdminLayout = lazy(() => import("./containers/Layout"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Transactions = lazy(() => import("./pages/Transactions"));
const CardPayment = lazy(() => import("./pages/CardPayment"));
const SupplyAccount = lazy(() => import("./pages/SupplyAccount"));
const SavingsAccount = lazy(() => import("./pages/SavingsAccount"));
const Commission = lazy(() => import("./pages/Commission"));
const RemittanceRia = lazy(() => import("./pages/RemittanceRia/Ria"));
const AdministratorProfile = lazy(() =>
  import("./pages/Administrator/Profile")
);
const UsersPdv = lazy(() => import("./pages/Users/Pdv"));
const UsersAddMasters = lazy(() => import("./pages/Users/AddMasters"));
const UsersMasters = lazy(() => import("./pages/Users/Masters"));
const UsersIndividuals = lazy(() => import("./pages/Users/Individuals"));
const UsersEntreprises = lazy(() => import("./pages/Users/Entreprises"));
const UsersBankTellers = lazy(() => import("./pages/Users/BankTellers"));
const ReportingAccount = lazy(() => import("./pages/ReportingAccount"));
const UsersIndividualsDetail = lazy(() =>
  import("./pages/Users/IndividualsDetail")
);
const UsersPdvDetail = lazy(() => import("./pages/Users/PdvDetail"));
const UsersMastersDetail = lazy(() => import("./pages/Users/MastersDetail"));
const UsersEntreprisesDetail = lazy(() =>
  import("./pages/Users/EntreprisesDetail")
);
const Cards = lazy(() => import("./pages/Cards"));
const PhysicalCreditCard = lazy(() =>
  import("./pages/Orders/PhysicalCreditCard")
);
const VirtualCreditCard = lazy(() =>
  import("./pages/Orders/VirtualCreditCard")
);
const AddAccount = lazy(() => import("./pages/AddAccount"));
const Transfer = lazy(() => import("./pages/Transfer"));
const EditAccount = lazy(() => import("./pages/EditAccount"));
const EditIndividuals = lazy(() => import("./pages/Users/EditIndividuals"));
const EditEntreprises = lazy(() => import("./pages/Users/EditEntreprises"));
const BlackList = lazy(() => import("./pages/BlackList"));
const EditUserDocument = lazy(() => import("./pages/EditUserDocument"));
const BankTellersDetail = lazy(() => import("./pages/Users/BankTellersDetail"));
const AddSalesman = lazy(() => import("./pages/AddSalesman"));
const Settings = lazy(() => import("./pages/Settings"));
const UsersBankTellersDetails = lazy(() =>
  import("./pages/Users/BankTellersDetail")
);
const SettingsGlobalBlocking = lazy(() => import("./pages/Settings/SettingsGlobalBlocking"));
const SettingsCommissionManagement = lazy(() => import("./pages/Settings/SettingsCommissionManagement"));
const SettingsEditCommissionManagement = lazy(() => import("./pages/Settings/SettingsEditCommissionManagement"));
const DetailSettingsCommission = lazy(() => import("./pages/Settings/DetailSettingsCommission"));
const EditSettingsItemCommission = lazy(() => import("./pages/Settings/EditSettingsItemCommission"));
const Destocking = lazy(() => import("./pages/Destocking"));
const ValidateCommission = lazy(() => import("./pages/ValidateCommission"));
const SettingsAccountLogs = lazy(() => import("./pages/Settings/SettingsAccountLogs"));




function PrivateRoute({ children, ...rest }) {
  const currentUser = getLocalState("currentUser");

  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname:navigations.LOGIN ,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <Suspense fallback={<InLineLoader />}>
      <Switch>
        <PrivateRoute exact={true} path={navigations.DASHBOARD}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Dashboard />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.TRANSACTIONS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Transactions />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.CARD_PAYMENT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <CardPayment />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.SUPPLY_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <SupplyAccount />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.SAVINGS_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <SavingsAccount />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.COMMISSION_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Commission />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.REMITTANCE_RIA}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <RemittanceRia />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact={true} path={navigations.ADMINISTRATOR_PROFILE}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <AdministratorProfile />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.USERS_PDV}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersPdv />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.TRANSFER}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Transfer />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.USERS_MASTERS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersMasters />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.USERS_ADDMASTERS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersAddMasters />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.USERS_INDIVIDUALS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersIndividuals />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.USERS_ENTREPRISES}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersEntreprises />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.USERS_BANKTELLERS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersBankTellers />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>


        <PrivateRoute exact path={navigations.USERS_BANKTELLERS_DETAIL}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <BankTellersDetail/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>


        <PrivateRoute path={navigations.REPORTING_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <ReportingAccount />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_INDIVIDUALS_DETAIL}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersIndividualsDetail />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_ENTREPRISES_EDIT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <EditEntreprises />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_INDIVIDUALS_EDIT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <EditIndividuals />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_PDV_DETAIL}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersPdvDetail />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_MASTERS_DETAIL}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersMastersDetail />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_ENTREPRISES_DETAIL}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <UsersEntreprisesDetail />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.CARDS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Cards />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.ORDERS_PHYSICAL_CREDIT_CARD}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <PhysicalCreditCard />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.ORDERS_VIRTUAL_CREDIT_CARD}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <VirtualCreditCard />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.ADMIN_ADD_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <AddAccount />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.ADMIN_EDIT_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <EditAccount />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.BLACKLIST_ACCOUNT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <BlackList />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.USERS_DOCUMENTS_EDIT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <EditUserDocument />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.ADD_SALESMAN}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <AddSalesman/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact={true} path={navigations.SETTINGS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Settings/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.SETTINGS_GLOBAL_BLOCKING}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <SettingsGlobalBlocking/>
            </Suspense>
          </AdminLayout>

        </PrivateRoute>

        <PrivateRoute exact={true} path={navigations.SETTINGS_COMMISSION_MANAGEMENT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <SettingsCommissionManagement/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.SETTINGS_EDIT_COMMISSION_MANAGEMENT}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <SettingsEditCommissionManagement/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>


        <PrivateRoute path={navigations.DETAIL_SETTINGS_COMMISSION}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <DetailSettingsCommission/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute path={navigations.EDIT_SETTINGS_ITEM_COMMISSION}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <EditSettingsItemCommission/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>


        <PrivateRoute path={navigations.DESTOCKING}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Destocking/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact path={navigations.VALIDATE_COMMISSION}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <ValidateCommission/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <PrivateRoute exact={true} path={navigations.SETTINGS_ACCOUNT_LOGS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader/>}>
              <SettingsAccountLogs/>
            </Suspense>
          </AdminLayout>
        </PrivateRoute>

        <Route path={navigations.LOGIN}>
          <Login />
        </Route>
        <Route path={navigations.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>

        <Route path={navigations.CHANGE_PASSWORD}>
          <ChangePassword/>
        </Route>

        <Route path={navigations.INIT_ADMIN_PASSWORD}>
          <InitAdminPassword/>
        </Route>

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
