import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import AdminService from "../../api/services/front/admin.service";
const _adminService = new AdminService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 * @private
 */

function* _settingsGlobalBlockingListSaga(payload_data) {
    const {payload} = payload_data;
    try {
        let appro_list= `params/get/bilan/1`;

        if (payload.full_name) {
            appro_list += `&full_name=${payload.full_name}`
        }

        if (payload.status) {
            appro_list += `&status=${payload.status}`
        }

        if (payload.date_debut) {
            appro_list += `&date_debut=${payload.date_debut}`
        }

        if (payload.date_fin) {
            appro_list += `&date_fin=${payload.date_fin}`
        }

        const response = yield call(_adminService._getData,appro_list);
        yield put(Actions.settingsGlobalBlockingSuccess(response?.data));
    } catch (error) {
        yield put(Actions.settingsGlobalBlockingFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}


export default function* settingsGlobalBlockingListSaga() {
    yield takeEvery(constants.SETTINGS_GLOBAL_BLOCKING_REQUESTING, _settingsGlobalBlockingListSaga);
}
