import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import AdminService from "../../api/services/front/admin.service";
import {getDocumentFailed, getDocumentSuccess} from "./actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createGetDocumentSaga(payload_data) {
    const {payload} = payload_data;
    try {
        const response = yield call(_adminService._getDocument,payload);
        yield put(getDocumentSuccess(response?.data));
    } catch (error) {
        yield put(getDocumentFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* getDocumentSaga() {
    yield takeEvery(Actions.GET_DOCUMENT_REQUESTING, createGetDocumentSaga);
}
