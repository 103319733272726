import * as constants from "./constants";

export const  userBlacklistRequest =(payload)=> {
    return { type: constants.USER_BLACKLIST_REQUESTING, payload }
}

export const userBlacklistSuccess=(payload)=>{
    return { type:constants.USER_BLACKLIST_SUCCESS, payload }
}

export const userBlacklistFailed=(error)=>{
    return { type: constants.USER_BLACKLIST_FAILED, error }
}
