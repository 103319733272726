import * as constants from "./constants";

export const  onboardUserRequest =(payload,page)=> {
  return { type: constants.ONBOARD_USER_REQUESTING,payload,page }
}

export const onboardUserSuccess=(payload)=>{
  return { type:constants.ONBOARD_USER_SUCCESS, payload }
}

export const onboardUserFailed=(error)=>{
  return { type:constants.ONBOARD_USER_FAILED, error }
}
