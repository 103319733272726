import * as constants from "./constants";

export const  settingsGlobalBlockingRequest =(payload)=> {
  return { type: constants.SETTINGS_GLOBAL_BLOCKING_REQUESTING,payload}
}

export const settingsGlobalBlockingSuccess=(payload)=>{
  return { type:constants.SETTINGS_GLOBAL_BLOCKING_SUCCESS, payload }
}

export const settingsGlobalBlockingFailed=(error)=>{
  return { type:constants.SETTINGS_GLOBAL_BLOCKING_FAILED, error }
}
