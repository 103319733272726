import * as constants from "./constants";

export const  transferBusinessPartRequest =(payload)=> {
  return { type: constants.TRANSFER_BUSINESS_PART_REQUESTING,payload }
}

export const transferBusinessPartSuccess=(payload)=>{
  return { type:constants.TRANSFER_BUSINESS_PART_SUCCESS, payload }
}

export const transferBusinessPartFailed=(error)=>{
  return { type:constants.TRANSFER_BUSINESS_PART_FAILED, error }
}
