import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {addMasterFailed, addMasterSuccess} from "./actions";
const _adminService= new AdminService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createMasterSaga(payload_data) {
    const {payload} = payload_data;
    const url='master/user';
    try {
        const response = yield call(_adminService.createUser,url, payload);
        yield put(addMasterSuccess(response?.data));
        yield call(Notify.success,"Le master a été créé avec succès")
        yield put(hideModal());
        payload.resetForm()
    } catch (error) {
        yield put(addMasterFailed(error));
        yield call(Notify.failure,error.message)
    }
}


export default function* addMaster() {
    yield takeEvery(Actions.ADD_MASTER_REQUESTING, createMasterSaga);
}
