import {createStore,combineReducers,applyMiddleware,compose} from 'redux';
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import loginReducer from "./login/reducer";
import rootSagas from "./saga";
import metricsReducer from "./metrics/reducer";
import modalReducer from "./modal/reducer";
import addAdminReducer from "./addAdmin/reducer";
import adminListReducer from "./adminList/reducer";
import setEditAdminReducer from "./setEditAdmin/reducer";
import approListReducer from "./approList/reducer";
import initSupplyReducer from "./initSupply/reducer";
import validateSupplyReducer from "./validateSupply/reducer";
import editAdminReducer from "./editAdmin/reducer";
import blockedOrUnblockedCardReducer from "./blockedOrUnblockedCard/reducer";
import orderPhysicalCardActionReducer from "./orderPhysicalCardAction/reducer";
import activateUserAccountReducer from "./ActivateUserAccount/reducer";
import transferListReducer from "./transferAgent/reducer";
import cumulTransactionListReducer from "./cumulTransactionList/reducer";
import historyTransactionReducer from "./historyTransaction/reducer";
import transferAgentReducer from "./transferAgent/reducer";
import transferAgentBusReducer from "./transferAgentBus/reducer";
import transferAgentPartReducer from "./transferAgentPart/reducer";
import transferBusinessReducer from "./transferBusiness/reducer";
import transferGuichetierPdvReducer from "./transferGuichetierPdv/reducer";
import transferParticulierReducer from "./transferParticulier/reducer";
import transferTranchReducer from "./transferTranch/reducer";
import supplyByWalletListReducer from "./supplyByWalletList/reducer";
import addSalesmanReducer from "./addSalesman/reducer";
import profileMenuReducer from "./profile-menu/reducer";
import editDocumentReducer from "./editDocument/reducer";
import onboardUserReducer from "./onboardUser/reducer";
import getDocumentReducer from "./getDocument/reducer";
import settingsGloblBlockingReducer from "./settingsGlobalBlockingList/reducer";
import editSettingsGlobalBlockingReducer from "./editSettingsGlobalBlocking/reducer";
import settingsCommissionReducer from "./settingsCommissionList/reducer";
import detailSettingsCommissionReducer from "./detailSettingsCommission/reducer";
import settingsEditCommissionReducer from "./settingsEditCommission/reducer";
import detailSettingsCommissionSaga from "./detailSettingsItemCommission/saga";
import detailSettingsItemCommissionReducer from "./detailSettingsItemCommission/reducer";
import settingsItemCommissionListReducer from "./settingsItemCommissionList/reducer";
import editSettingsItemCommissionReducer from "./editSettingsItemCommission/reducer";
import userBlacklistReducer from "./userBlacklist/reducer";
import editUserBlacklistReducer from "./editUserBlacklist/reducer";
import initAdminPasswordReducer from "./initAdminPassword/reducer";
import validateCommissionReducer from "./validateCommission/reducer";
import validateCommissionListReducer from "./validateCommissionList/reducer";
import validateDestockingReducer from "./validateDestocking/reducer";
import destockingListReducer from "./destockingList/reducer";
import attachOrDetachRoleReducer from "./attachOrDetachRole/reducer";
import addMasterReducer from "./addMaster/reducer";
import forgotPasswordReducer from "./forgotPassword/reducer";
import settingsAccountLogsListReducer from "./settingsAccountLogsList/reducer";

const rootReducer = combineReducers({
    login:loginReducer,
    metrics:metricsReducer,
    modal:modalReducer,
    addAdmin:addAdminReducer,
    adminList:adminListReducer,
    setEditAdmin:setEditAdminReducer,
    approList:approListReducer,
    initSupply:initSupplyReducer,
    validateSupply:validateSupplyReducer,
    editAdmin:editAdminReducer,
    blockedOrUnblocked:blockedOrUnblockedCardReducer,
    orderPhysicalCardAction:orderPhysicalCardActionReducer,
    activateUserAccount:activateUserAccountReducer,
    transferList:transferListReducer,
    cumulTransactionList:cumulTransactionListReducer,
    historyTransaction:historyTransactionReducer,
    transferAgent:transferAgentReducer,
    transferAgentBus:transferAgentBusReducer,
    transferAgentPart:transferAgentPartReducer,
    transferBusiness:transferBusinessReducer,
    transferGuichetierPdv:transferGuichetierPdvReducer,
    transferParticulier:transferParticulierReducer,
    transferTranch:transferTranchReducer,
    supplyByWalletList:supplyByWalletListReducer,
    addSalesman:addSalesmanReducer,
    profileMenu:profileMenuReducer,
    editDocument:editDocumentReducer,
    getDocument:getDocumentReducer,
    onboardUser:onboardUserReducer,
    settingsGloblBlocking:settingsGloblBlockingReducer,
    editSettingsGlobalBlocking:editSettingsGlobalBlockingReducer,
    settingsCommission:settingsCommissionReducer,
    detailSettingsCommission:detailSettingsCommissionReducer,
    settingsItemCommissionList:settingsItemCommissionListReducer,
    settingsEditCommission:settingsEditCommissionReducer,
    detailSettingsCommissionSaga:detailSettingsCommissionSaga,
    detailSettingsItemCommission: detailSettingsItemCommissionReducer,
    editSettingsItemCommission:editSettingsItemCommissionReducer,
    userBlacklist:userBlacklistReducer,
    editUserBlacklist:editUserBlacklistReducer,
    initAdminPassword:initAdminPasswordReducer,
    validateCommission:validateCommissionReducer,
    validateCommissionList:validateCommissionListReducer,
    validateDestocking:validateDestockingReducer,
    destockingList:destockingListReducer,
    attachOrDetachRole:attachOrDetachRoleReducer,
    addMaster:addMasterReducer,
    forgotPassword:forgotPasswordReducer,
    settingsAccountLogsList:settingsAccountLogsListReducer,
})


const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer,composeEnhancers(applyMiddleware(sagaMiddleware,logger)))
sagaMiddleware.run(rootSagas)
