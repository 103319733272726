import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {settingsEditCommissionFailed, settingsEditCommissionSuccess} from "./actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createSettingsEditCommissionSaga(payload_data) {
    const {payload} = payload_data;
    const url=`params/operateur/${payload.id}`
    try {
        const response = yield call(_adminService.updateData,url, payload);
        yield put(settingsEditCommissionSuccess(response?.data));
        yield call(Notify.success,"L'élément a été modifié avec succès")
        yield put(hideModal());

    } catch (error) {
        yield put(settingsEditCommissionFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* settingsEditCommissionSaga() {
    yield takeEvery(Actions.SETTINGS_EDIT_COMMISSION_REQUESTING, createSettingsEditCommissionSaga);
}
