import {call, put, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import AdminService from "../../api/services/front/admin.service";
import {detailSettingsItemCommissionFailed, detailSettingsItemCommissionSuccess} from "./actions";

const _adminService= new AdminService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */

function* createDetailSettingsItemCommissionSaga(payload_data) {
    const {payload} = payload_data;
    const url=`params/commission/${payload}`
    try {
        const response = yield call(_adminService._getData,url);
        yield put(detailSettingsItemCommissionSuccess(response?.data));
    } catch (error) {
        yield put(detailSettingsItemCommissionFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* detailSettingsItemCommissionSaga() {
    yield takeEvery(Actions.DETAIL_SETTINGS_ITEM_COMMISSION_REQUESTING, createDetailSettingsItemCommissionSaga);
}
