import * as constants from "./constants";

export const  addMasterRequest =(payload)=> {
  return { type: constants.ADD_MASTER_REQUESTING, payload }
}

export const addMasterSuccess=(payload)=>{
  return { type:constants.ADD_MASTER_SUCCESS, payload }
}

export const addMasterFailed=(error)=>{
  return { type:constants.ADD_MASTER_FAILED, error }
}
