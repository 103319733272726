import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {editSettingsGlobalBlockingFailed, editSettingsGlobalBlockingSuccess} from "./actions";
import {settingsGlobalBlockingRequest} from "../settingsGlobalBlockingList/actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createEditSettingsGlobalBlockingSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_adminService.updateGlobalBlocking, payload,payload.id);
        yield put(editSettingsGlobalBlockingSuccess(response?.data));
        yield call(Notify.success,"L'élément a été modifié avec succès")
        yield put(hideModal());
        yield put(settingsGlobalBlockingRequest( 1));
    } catch (error) {
        yield put(editSettingsGlobalBlockingFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* editSettingsGlobalBlockingSaga() {
    yield takeEvery(Actions.EDIT_SETTINGS_GLOBAL_BLOCKING_REQUESTING, createEditSettingsGlobalBlockingSaga);
}
