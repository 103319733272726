import * as constants from "./constants";

export const  transferAgentRequest =(payload)=> {
  return { type: constants.TRANSFER_AGENT_REQUESTING,payload }
}

export const transferAgentSuccess=(payload)=>{
  return { type:constants.TRANSFER_AGENT_SUCCESS, payload }
}

export const transferAgentFailed=(error)=>{
  return { type:constants.TRANSFER_AGENT_FAILED, error }
}
