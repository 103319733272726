import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import ApproService from "../../api/services/front/appro.service";
import {validateSupplyFailed, validateSupplySuccess} from "./actions";
import {approListRequest} from "../approList/actions";
const _approService= new ApproService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */
function* createValidateSupplySaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_approService.validateSupply, payload,payload?.id_initialisation);
        yield put(validateSupplySuccess(response?.data));
        yield call(Notify.success,`L'approvisionnement à été ${payload.status === 'VALIDATED' ? 'validé' : 'rejeté'}`)
        yield put(approListRequest({
            full_name:'',
            status:'',
            date_debut:'',
            date_fin:''
        },1));
        yield put(hideModal());
    } catch (error) {
        yield put(validateSupplyFailed(error));
        yield call(Notify.failure,error.message)
    }

}


export default function* ValidateSupplySaga() {
    yield takeEvery(Actions.VALIDATE_SUPPLY_REQUESTING, createValidateSupplySaga);
}
