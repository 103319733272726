import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import AdminService from "../../api/services/front/admin.service";
import {userBlacklistFailed, userBlacklistSuccess} from "./actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createUserBlacklistSaga(payload_data) {
    const {payload} = payload_data;
    const url=`params/listenoire/${payload.id}`;
    try {
        const response = yield call(_adminService._getData,url);
        yield put(userBlacklistSuccess(response?.data));
    } catch (error) {
        yield put(userBlacklistFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* userBlacklistSaga() {
    yield takeEvery(Actions.USER_BLACKLIST_REQUESTING, createUserBlacklistSaga);
}
