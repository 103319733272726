import * as constants from "./constants";

export const  validateCommissionRequest =(payload)=> {
  return { type: constants.VALIDATE_COMMISSION_REQUESTING, payload }
}

export const validateCommissionSuccess=(payload)=>{
  return { type:constants.VALIDATE_COMMISSION_SUCCESS, payload }
}

export const validateCommissionFailed=(error)=>{
  return { type:constants.VALIDATE_COMMISSION_FAILED, error }
}
