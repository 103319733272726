import * as constants from "./constants";

export const initialState = {
  isLoading: false,
  messages: [],
  errors: null,
  isAuthenticated: false,
  user: null,
  wallet:null,
  token: null | localStorage.getItem('access_token') ,
}

const loginReducer = (state = initialState, action)=> {
  switch (action.type) {

    case constants.LOGIN_REQUESTING:
      return {
        ...state,
        isLoading: true,
        messages: [{ body: 'Logging in...', time: new Date() }],
      };

    case constants.LOGIN_SUCCESS:
      localStorage.setItem('token',action.payload?.access_token);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload?.user?.id,
        token: action.payload?.access_token,
        entreprise:action.payload?.company_id,
        wallet:action.payload?.user?.wallet?.id,
        errors: null,
        messages: []
      };

    case constants.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errors:action.error,
        messages: [],
        user: null,
        entreprise:null,
        wallet:null,
        token:null
      };

    default:
      return state;
  }
}

export default loginReducer
