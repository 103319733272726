import * as constants from "./constants";

export const  addAdminRequest =(payload)=> {
  return { type: constants.ADD_ADMIN_REQUESTING, payload }
}

export const addAdminSuccess=(payload)=>{
  return { type:constants.ADD_ADMIN_SUCCESS, payload }
}

export const addAdminFailed=(error)=>{
  return { type:constants.ADD_ADMIN_FAILED, error }
}
