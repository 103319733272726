import * as constants from "./constants";

export const  metricsRequest =()=> {
  return { type: constants.METRICS_REQUESTING}
}

export const metricsSuccess=(payload)=>{
  return { type:constants.METRICS_SUCCESS, payload }
}

export const metricsFailed=(error)=>{
  return { type:constants.METRICS_FAILED, error }
}
