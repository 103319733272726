import * as constants from "./constants";

export const  settingsEditCommissionRequest =(payload)=> {
    return { type: constants.SETTINGS_EDIT_COMMISSION_REQUESTING, payload }
}

export const settingsEditCommissionSuccess=(payload)=>{
    return { type:constants.SETTINGS_EDIT_COMMISSION_SUCCESS, payload }
}

export const settingsEditCommissionFailed=(error)=>{
    return { type:constants.SETTINGS_EDIT_COMMISSION_FAILED, error }
}
