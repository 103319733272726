import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import AdminService from "../../api/services/front/admin.service";
const _adminService= new AdminService()

/**
 * ma saga login
 * @param loginPayload
 * @returns {Generator<*, void, *>}
 */
function* _validateCommissionListSaga(payload_data) {
    const {payload} = payload_data;
    const {page} = payload_data;


    let url= `validation/commissions?page=${page}`;

    if (payload?.full_name!=='') {
        url += `&full_name=${payload.full_name}`
    }

    if (payload?.status!=='') {
        url += `&status=${payload.status}`
    }



    try {

        const response = yield call(_adminService._getData,url);
        yield put(Actions.validateCommissionListSuccess(response?.data));
    } catch (error) {
        yield put(Actions.validateCommissionListFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}


export default function* validateCommissionListSaga() {
    yield takeEvery(constants.VALIDATE_COMMISSION_LIST_REQUESTING, _validateCommissionListSaga);
}
