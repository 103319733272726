import * as constants from "./constants";

export const  validateSupplyRequest =(payload)=> {
  return { type: constants.VALIDATE_SUPPLY_REQUESTING, payload }
}

export const validateSupplySuccess=(payload)=>{
  return { type:constants.VALIDATE_SUPPLY_SUCCESS, payload }
}

export const validateSupplyFailed=(error)=>{
  return { type:constants.VALIDATE_SUPPLY_FAILED, error }
}
