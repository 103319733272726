import * as constants from "./constants";

export const  settingsAccountLogsListRequest =(payload)=> {
  return { type: constants.SETTINGS_ACCOUNT_LOGS_LIST_REQUESTING,payload}
}

export const settingsAccountLogsListSuccess=(payload)=>{
  return { type:constants.SETTINGS_ACCOUNT_LOGS_LIST_SUCCESS, payload }
}

export const settingsAccountLogsListFailed=(error)=>{
  return { type:constants.SETTINGS_ACCOUNT_LOGS_LIST_FAILED, error }
}
