import * as constants from "./constants";

export const  detailSettingsCommissionRequest =(payload)=> {
    return { type: constants.DETAIL_SETTINGS_COMMISSION_REQUESTING, payload }
}

export const detailSettingsCommissionSuccess=(payload)=>{
    return { type:constants.DETAIL_SETTINGS_COMMISSION_SUCCESS, payload }
}

export const detailSettingsCommissionFailed=(error)=>{
    return { type:constants.DETAIL_SETTINGS_COMMISSION_FAILED, error }
}
