import * as constants from "./constants";

export const  approListRequest =(payload,page)=> {
  return { type: constants.APPRO_LIST_REQUESTING,payload,page}
}

export const approListSuccess=(payload)=>{
  return { type:constants.APPRO_LIST_SUCCESS, payload }
}

export const approListFailed=(error)=>{
  return { type:constants.APPRO_LIST_FAILED, error }
}
