import * as constants from "./constants";

export const  getDocumentRequest =(payload)=> {
    return { type: constants.GET_DOCUMENT_REQUESTING, payload }
}

export const getDocumentSuccess=(payload)=>{
    return { type:constants.GET_DOCUMENT_SUCCESS, payload }
}

export const getDocumentFailed=(error)=>{
    return { type:constants.GET_DOCUMENT_FAILED, error }
}
