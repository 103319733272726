import * as constants from "./constants";

export const  settingsItemCommissionListRequest =(payload)=> {
    return { type: constants.SETTINGS_ITEM_COMMISSION_LIST_REQUESTING, payload }
}

export const settingsItemCommissionListSuccess=(payload)=>{
    return { type:constants.SETTINGS_ITEM_COMMISSION_LIST_SUCCESS, payload }
}

export const settingsItemCommissionListFailed=(error)=>{
    return { type:constants.SETTINGS_ITEM_COMMISSION_LIST_FAILED, error }
}
