import * as constants from "./constants";

export const  ActivateUserAccountRequest =(payload)=> {
    return { type: constants.ACTIVATE_USER_ACCOUNT_REQUESTING, payload }
}

export const ActivateUserAccountSuccess=(payload)=>{
    return { type:constants.ACTIVATE_USER_ACCOUNT_SUCCESS, payload }
}

export const ActivateUserAccountFailed=(error)=>{
    return { type:constants.ACTIVATE_USER_ACCOUNT_FAILED, error }
}
