import * as constants from "./constants";

export const  transferAgentPartRequest =(payload)=> {
  return { type: constants.TRANSFER_AGENT_PART_REQUESTING,payload }
}

export const transferAgentPartSuccess=(payload)=>{
  return { type:constants.TRANSFER_AGENT_PART_SUCCESS, payload }
}

export const transferAgentPartFailed=(error)=>{
  return { type:constants.TRANSFER_AGENT_PART_FAILED, error }
}
