import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {setEditAdminFailed, setEditAdminSuccess} from "./actions";

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createSetEditAdminSaga(payload_data) {
    const {payload} = payload_data;

    try {
        yield put(setEditAdminSuccess(payload));
    } catch (error) {
        yield put(setEditAdminFailed(error));
        yield call(Notify.failure,'Une erreur c\'est produite')
    }
}

export default function* setEditAdminSaga() {
    yield takeEvery(Actions.SET_EDIT_ADMIN_REQUESTING, createSetEditAdminSaga);
}
