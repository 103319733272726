import * as constants from "./constants";

export const initialState = {
   data: [],
   isLoading:false,
   errors: null
}

const settingsItemCommissionListReducer = (state = initialState, action)=> {
  switch (action.type) {

    case constants.SETTINGS_ITEM_COMMISSION_LIST_REQUESTING:
      return {
        ...state,
        isLoading: true,
        messages: [{ body: 'Logging in...', time: new Date() }],
      };

    case constants.SETTINGS_ITEM_COMMISSION_LIST_SUCCESS:
      return {
        ...state,
        data:action.payload,
        errors: null,
        isLoading: false,
        messages: []
      };

    case constants.SETTINGS_ITEM_COMMISSION_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errors:action.error,
        messages: [],
        data: null,
      };

    default:
      return state;
  }
}

export default settingsItemCommissionListReducer
