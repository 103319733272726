import ApiService from "./api.service";

const adminEndpoint={
    create_admin: 'create/admin',
    admin_list: 'role/admin',
    update_admin: 'put/admin',
    activate_account: 'activate/compte',
    create_commercial: 'commercial/user',
    onboard_user: 'activate/compte',
    global_blocking: 'params/update/bilan'
}


/**
 * Api de configuration
 * @type {ApiService}
 */
const apiUrl= new ApiService()
class AdminService {
  /**
   *
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   * @private
   */
  createUser(url, data) {
    return apiUrl
      ._post(url, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  }
  createAdmin(data) {
    return apiUrl
      ._post(adminEndpoint.create_admin, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  }

  _getAdminList() {
    return apiUrl
      ._get(adminEndpoint.admin_list)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  }

  updateAdmin(data, id_admin) {
    return apiUrl
      ._put(adminEndpoint.update_admin + `/${id_admin}`, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  }

    updateGlobalBlocking(data, id) {
        return apiUrl
            ._put(adminEndpoint.global_blocking + `/${id}`, data)
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error.response.data);
            });
    }


    activateAccount(data,id_user){
        return apiUrl._put(adminEndpoint.activate_account+`/${id_user}`,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    createSalesman(data){
        return apiUrl._post(adminEndpoint.create_commercial,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    createUser(url,data){
        return apiUrl._post(url,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    updateDocument(data,id_admin){
        const token = localStorage.getItem('token');

        return apiUrl._post(`edit/piece/user/${id_admin}`,data,{
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token,
            },
        }).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })

    }

    _getDocument(id_user){
        return apiUrl._get(`piece/user/${id_user}`).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    _getData(url){
        return apiUrl._get(url).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    _postData(url,data){
        return apiUrl._post(url,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

    updateData(url,data) {
        return apiUrl
            ._put(url , data)
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error.response.data);
            });
    }


  activateAccount(data, id_user) {
    return apiUrl
      ._put(adminEndpoint.activate_account + `/${id_user}`, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  }
}

export default AdminService;
