import * as constants from "./constants";

export const  detailSettingsItemCommissionRequest =(payload)=> {
    return { type: constants.DETAIL_SETTINGS_ITEM_COMMISSION_REQUESTING, payload }
}

export const detailSettingsItemCommissionSuccess=(payload)=>{
    return { type:constants.DETAIL_SETTINGS_ITEM_COMMISSION_SUCCESS, payload }
}

export const detailSettingsItemCommissionFailed=(error)=>{
    return { type:constants.DETAIL_SETTINGS_ITEM_COMMISSION_FAILED, error }
}
