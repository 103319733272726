import {put, call,takeLatest} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import AdminService from "../../api/services/front/admin.service";
const _adminService= new AdminService()

/**
 * ma saga login
 * @param loginPayload
 * @returns {Generator<*, void, *>}
 */
function* _adminListSaga() {
    try {
        const response = yield call(_adminService._getAdminList);
        yield put(Actions.adminListSuccess(response?.data));
    } catch (error) {
        yield put(Actions.adminListFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}


export default function* adminListSaga() {
    yield takeLatest(constants.ADMIN_LIST_REQUESTING, _adminListSaga);
}
