import * as constants from "./constants";

export const  transferBusinessRequest =(payload)=> {
  return { type: constants.TRANSFER_BUSINESS_REQUESTING,payload }
}

export const transferBusinessSuccess=(payload)=>{
  return { type:constants.TRANSFER_BUSINESS_SUCCESS, payload }
}

export const transferBusinessFailed=(error)=>{
  return { type:constants.TRANSFER_BUSINESS_FAILED, error }
}
