import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import ApproService from "../../api/services/front/appro.service";
import {initSupplyFailed, initSupplySuccess} from "./actions";
import {approListRequest} from "../approList/actions";
const _approService= new ApproService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */
function* createInitSupplySaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_approService.createInitSupply, payload);
        yield put(initSupplySuccess(response?.data));
        yield call(Notify.success,"L'approvisionnement à été initialisée")
        yield put(approListRequest({ full_name:'', status:'', date_debut:'', date_fin:''},1));
        yield put(hideModal());
    } catch (error) {
        yield put(initSupplyFailed(error));
        yield call(Notify.failure,error.message)
    }

}


export default function* InitSupplySaga() {
    yield takeEvery(Actions.INIT_SUPPLY_REQUESTING, createInitSupplySaga);
}
