export const DASHBOARD = "/";
export const LOGIN = "/auth/login";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const CHANGE_PASSWORD = "/auth/change-password/:token/account";
export const INIT_ADMIN_PASSWORD = "/auth/init-password/:token/admin";
export const TRANSACTIONS = "/transactions";
export const TRANSFER = "/transfer";
export const DESTOCKING = "/destocking";
export const CARD_PAYMENT = "/card-payment";
export const SUPPLY_ACCOUNT = "/supply-account";
export const SAVINGS_ACCOUNT = "/savings-account";
export const COMMISSION_ACCOUNT = "/commission-account";
export const REMITTANCE_RIA = "/remittance/ria";
export const ADMINISTRATOR_PROFILE = "/administrator/profile";
export const USERS_PDV = "/users/pdv";
export const USERS_MASTERS = "/users/masters";
export const USERS_ADDMASTERS = "/users/add-masters";
export const USERS_INDIVIDUALS = "/users/individuals";
export const USERS_ENTREPRISES = "/users/entreprises";
export const USERS_BANKTELLERS = "/users/bankTellers";
export const REPORTING_ACCOUNT = "/reporting-account";
export const USERS_INDIVIDUALS_DETAIL =
  "/users/individuals/:id/detail/:wallet_id";
export const USERS_PDV_DETAIL = "/users/pdv/:id/detail/:wallet_id";
export const USERS_MASTERS_DETAIL = "/users/masters/:id/detail/:wallet_id";
export const USERS_ENTREPRISES_DETAIL =
  "/users/entreprises/:id/detail/:wallet_id";
export const CARDS = "/cards";
export const ORDERS_PHYSICAL_CREDIT_CARD = "/orders/physical-credit-card";
export const ORDERS_VIRTUAL_CREDIT_CARD = "/orders/virtual-credit-card";
export const ADMIN_ADD_ACCOUNT = "/administrator/add-account";
export const ADMIN_EDIT_ACCOUNT = "/administrator/:id/edit-account";
export const USERS_INDIVIDUALS_EDIT = "/users/individuals/:id/edit";
export const USERS_ENTREPRISES_EDIT = "/users/:user_id/entreprises/:id/edit";
export const BLACKLIST_ACCOUNT = "/black-lists";
export const USERS_DOCUMENTS_EDIT = "/users/documents/:id/edit";
export const ADD_SALESMAN = '/administrator/add-salesman';
export const SETTINGS = '/settings';
export const USERS_BANKTELLERS_DETAIL = "/users/bankTellers/:id/detail/:wallet_id";
export const SETTINGS_GLOBAL_BLOCKING = '/settings/global-blocking';
export const SETTINGS_COMMISSION_MANAGEMENT = '/settings/commission-management';
export const SETTINGS_EDIT_COMMISSION_MANAGEMENT = '/settings/commission-management/:id/edit';
export const DETAIL_SETTINGS_COMMISSION= '/settings/commission-management/:id/detail';
export const EDIT_SETTINGS_ITEM_COMMISSION = '/settings/commission-item/:id/edit';
export const VALIDATE_COMMISSION = '/validate/commission';
export const SETTINGS_ACCOUNT_LOGS = '/settings/account-logs';


