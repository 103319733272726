import {call, put, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import AdminService from "../../api/services/front/admin.service";
import {settingsItemCommissionListFailed, settingsItemCommissionListSuccess} from "./actions";

const _adminService= new AdminService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */

function* createSettingsItemCommissionListSaga(payload_data) {
    const {payload} = payload_data;
    const url=`params/list/commission/${payload.id}?page=${payload.page}`
    try {

        const response = yield call(_adminService._getData,url);
        yield put(settingsItemCommissionListSuccess(response?.data?.commissions));
    } catch (error) {
        yield put(settingsItemCommissionListFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* settingsItemCommissionListSaga() {
    yield takeEvery(Actions.SETTINGS_ITEM_COMMISSION_LIST_REQUESTING, createSettingsItemCommissionListSaga);
}
