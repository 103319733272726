import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import DashboardService from "../../api/services/front/dashboard.service";
import {metricsFailed, metricsSuccess} from "./actions";
const dashboardService= new DashboardService()


function* fetchMetricsSaga() {
  try {
    const response = yield call(dashboardService.getMetrics);
    yield put(metricsSuccess(response?.data));
  } catch (error) {
    yield put(metricsFailed(error));
  }
}


export default function* metricsSaga() {
    yield takeEvery(Actions.METRICS_REQUESTING, fetchMetricsSaga);
}
