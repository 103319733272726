import {put, call, takeEvery} from 'redux-saga/effects';
import AuthService from "../../api/services/front/auth.service";
import {setLocalState} from "../../helper/localStorage";
import {loginFailed, loginSuccess} from "./actions";
import * as Actions from "./constants";
import * as navigations from "../../constants/navigation";
import {Notify} from "notiflix";
const auth= new AuthService()

/**
 * ma saga login
 * @param loginPayload
 * @returns {Generator<*, void, *>}
 */
function* loginAuthSaga(loginPayload) {
    const {payload} = loginPayload;
    const {history}=loginPayload;
    try {
        const response = yield call(auth.loginAuth, payload);
        console.log("Data is : ",response?.data);
        yield put(loginSuccess(response?.data));
        yield call(setLocalState, 'currentUser', response?.data);
        yield call(history.push,navigations.DASHBOARD);
        yield call(Notify.success,"Bienvenue dans votre espace.")
    } catch (error) {
        yield put(loginFailed(error));
        yield call(Notify.failure,"Votre identifiant ou mot de passe est incorrect.")
    }
}


export default function* authSaga() {
    yield takeEvery(Actions.LOGIN_REQUESTING, loginAuthSaga);
}
