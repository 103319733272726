import * as Actions from "./constants";

export const initialState = {
  data: null,
  isOpenAddAdmin:false,
  isOpenAddSalesman:false,
  isOpenAddMaster:false,
  isOpenDetailMetrics:false,
  isOpenBoxDetail:false,
  isOpenInitSupply:false,
  isOpenInitSupplyConfirmed:false,
  isOpenSupplyConfirmed:false,
  isOpenBlockedOrUnblockedCard:false,
  isOpenOrderPhysicalCardAction:false,
  isOpenDetailMovementSavings:false,
  isOpenActivateUserAccount:false,
  isOpenInitSupplyDetail:false,
  isOpenSupplyDetail:false,
  isOpenCumulDetail:false,
  isOpenDetailTransactionHistory:false,
  isOpenForgotPassword:false,
  isOpenChangePassword:false,
  isOpenAttachOrDetachRole:false,
  isOpenValidateCommissionConfirmed:false,
  isOpenDetailAccountLogs:false,
  isNavbarOpen:true,
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SHOW_ADD_ADMIN_MODAL:
      return {
        ...state,
          data:action.payload,
          isOpenAddAdmin:true,
          isOpenAddSalesman:false,
          isOpenAddMaster:false,
          isOpenDetailMetrics:false,
          isOpenBoxDetail:false,
          isOpenInitSupply:false,
          isOpenInitSupplyConfirmed:false,
          isOpenBlockedOrUnblockedCard:false,
          isOpenOrderPhysicalCardAction:false,
          isOpenSupplyConfirmed:false,
          isOpenDetailMovementSavings:false,
          isOpenActivateUserAccount:false,
          isOpenInitSupplyDetail:false,
          isOpenSupplyDetail:false,
          isOpenCumulDetail:false,
          isOpenDetailTransactionHistory:false,
          isOpenChangePassword:false,
          isOpenAttachOrDetachRole:false,
          isOpenValidateCommissionConfirmed:false,
          isOpenDetailAccountLogs:false,
          isNavbarOpen:false,
      };

    case  Actions.SHOW_DETAIL_METRICS_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:true,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false,
      };

    case  Actions.SHOW_BOX_DETAIL_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:true,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_INIT_SUPPLY_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:true,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_INIT_SUPPLY_CONFIRMED_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:true,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_SUPPLY_CONFIRMED_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:true,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_BLOCKED_OR_UNBLOCKED_CARD_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:true,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_ORDER_PHYSICAL_CARD_ACTION_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:true,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_DETAIL_MOVEMENT_SAVINGS_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:true,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.ACTIVATION_COMPTE_USER_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:true,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_SUPPLY_DETAIL_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:true,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_CUMUL_DETAIL_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:true,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_DETAIL_HISTORY_TRANSACTION_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:true,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_ADD_SALESMAN_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:true,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:true,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.ATTACH_OR_DETACH_CONFIRMED_ROLE_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:true,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_VALIDATION_COMMISSION_CONFIRMED_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:true,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_ADD_MASTER_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:true,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:false,
        isNavbarOpen:false
      };

    case  Actions.SHOW_DETAIL_ACCOUNT_LOGS_MODAL:
      return {
        ...state,
        data:action.payload,
        isOpenAddAdmin:false,
        isOpenDetailMetrics:false,
        isOpenBoxDetail:false,
        isOpenInitSupply:false,
        isOpenInitSupplyConfirmed:false,
        isOpenBlockedOrUnblockedCard:false,
        isOpenOrderPhysicalCardAction:false,
        isOpenSupplyConfirmed:false,
        isOpenDetailMovementSavings:false,
        isOpenActivateUserAccount:false,
        isOpenInitSupplyDetail:false,
        isOpenSupplyDetail:false,
        isOpenCumulDetail:false,
        isOpenDetailTransactionHistory:false,
        isOpenAddSalesman:false,
        isOpenAddMaster:false,
        isOpenChangePassword:false,
        isOpenAttachOrDetachRole:false,
        isOpenValidateCommissionConfirmed:false,
        isOpenDetailAccountLogs:true,
        isNavbarOpen:false
      };

    case Actions.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default modalReducer;
