import React from "react";
import {BrowserRouter} from "react-router-dom";
import {store} from "./redux/store";
import {Provider} from "react-redux";
import LanguageProvider from "./contexts/language/language.provider";
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider} from 'baseui';
import Routes from "./routes";
import {client} from "./graphql/client";
import {ApolloProvider} from "@apollo/client";

function App() {
  const engine = new Styletron();

  const messages = {
    //en: localEn,
    // ar: localAr,
    /*   es: localEs,
       de: localDe,
       zh: localCn,
       he: localIl,*/
  };
  return (
      <ApolloProvider client={client}>
          <Provider store={store}>
            <LanguageProvider messages={messages}>
              <StyletronProvider value={engine}>
                <BaseProvider theme={LightTheme}>
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>
                </BaseProvider>
              </StyletronProvider>
            </LanguageProvider>
          </Provider>
      </ApolloProvider>
  );
}

export default App;
