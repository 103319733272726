import ApiService from "./api.service";

const authEndpoint={
  //  login: 'login',
    login: 'login/admin',
    forgot_password: 'forgot/password'

}

/**
 * Api de configuration
 * @type {ApiService}
 */
const apiUrl= new ApiService()
class AuthService {

    /**
     * Service de connexion au compte
     * @param todo
     * @returns {Promise<AxiosResponse<any>>}
     */
    loginAuth(data){
       return apiUrl._post(authEndpoint.login,data).then(response=>{
           return Promise.resolve(response);
       }).catch(error=>{
           return Promise.reject(error.response);
        })
    }

    forgotPasswordAuth(data) {
        return apiUrl._post(authEndpoint.forgot_password, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error.response);
        })
    }

    initAdminPassword(data) {
        return apiUrl
            ._post(`change/password` , data)
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error.response.data);
            });
    }

}

export default AuthService
