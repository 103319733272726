import ApiService from "./api.service";

const adminEndpoint={
    create_admin: 'create/admin',
    admin_list: 'role/admin',
    update_admin: 'put/admin',
    activate_account: 'activate/compte',
}

/**
 * Api de configuration
 * @type {ApiService}
 */
const apiUrl= new ApiService()
class TransferService {

    /**
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     * @private
     */


    _getTransfert(url){
        return apiUrl._get(url).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response.data);
        })
    }

}

export default TransferService
