import * as constants from "./constants";

export const  cumulTransactionListRequest =(payload,page)=> {
  return { type: constants.CUMUL_TRANSACTION_LIST_REQUESTING,payload,page }
}

export const cumulTransactionListSuccess=(payload)=>{
  return { type:constants.CUMUL_TRANSACTION_LIST_SUCCESS, payload }
}

export const cumulTransactionListFailed=(error)=>{
  return { type:constants.CUMUL_TRANSACTION_LIST_FAILED, error }
}
