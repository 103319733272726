import * as constants from "./constants";

export const  hideModal =()=> {
    return { type: constants.HIDE_MODAL}
}

export const addAdminModal=(payload)=>{
    return { type:constants.SHOW_ADD_ADMIN_MODAL, payload}
}

export const addSalesmanModal=(payload)=>{
    return { type:constants.SHOW_ADD_SALESMAN_MODAL, payload}
}

export const addMasterModal=(payload)=>{
    return { type:constants.SHOW_ADD_MASTER_MODAL, payload}
}

export const detailMetricsModal=(payload)=>{
    return { type:constants.SHOW_DETAIL_METRICS_MODAL, payload}
}

export const boxDetailModal=(payload)=>{
    return { type:constants.SHOW_BOX_DETAIL_MODAL, payload}
}

export const initSupplyModal=(payload)=>{
    return { type:constants.SHOW_INIT_SUPPLY_MODAL, payload}
}

export const initSupplyConfirmedModal=(payload)=>{
    return { type:constants.SHOW_INIT_SUPPLY_CONFIRMED_MODAL, payload}
}

export const supplyConfirmedModal=(payload)=>{
    return { type:constants.SHOW_SUPPLY_CONFIRMED_MODAL, payload}
}

export const validationCommissionConfirmedModal=(payload)=>{
    return { type:constants.SHOW_VALIDATION_COMMISSION_CONFIRMED_MODAL, payload}
}

export const blockedOrUndblockedModal=(payload)=>{
    return { type:constants.SHOW_BLOCKED_OR_UNBLOCKED_CARD_MODAL, payload}
}

export const orderPhysicalCardActionModal=(payload)=>{
    return { type:constants.SHOW_ORDER_PHYSICAL_CARD_ACTION_MODAL, payload}
}

export const detailMovementSavingsModal=(payload)=>{
    return { type:constants.SHOW_DETAIL_MOVEMENT_SAVINGS_MODAL, payload}
}

export const activationCompteUserModal=(payload)=>{
    return { type:constants.ACTIVATION_COMPTE_USER_MODAL, payload}
}

export const showSupplyDetailModal=(payload)=>{
    return { type:constants.SHOW_SUPPLY_DETAIL_MODAL, payload}
}

export const showCumulDetailModal=(payload)=>{
    return { type:constants.SHOW_CUMUL_DETAIL_MODAL, payload}
}

export const showDetailHistoryTransactionModal=(payload)=>{
    return { type:constants.SHOW_DETAIL_HISTORY_TRANSACTION_MODAL, payload}
}


export const forgotPassWordModal = (payload) => {
    return { type:constants.SHOW_FORGOT_PASSWORD_MODAL, payload}
}

export const changePassWordModal = (payload) => {
    return { type:constants.SHOW_CHANGE_PASSWORD_MODAL, payload}
}

export const attachOrDetatchConfirmedModal=(payload)=>{
    return { type:constants.ATTACH_OR_DETACH_CONFIRMED_ROLE_MODAL, payload}
}

export const showDetailAccountLogsModal=(payload)=>{
    return { type:constants.SHOW_DETAIL_ACCOUNT_LOGS_MODAL, payload}
}
