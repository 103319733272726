import * as constants from "./constants";

export const  editSettingsGlobalBlockingRequest =(payload)=> {
    return { type: constants.EDIT_SETTINGS_GLOBAL_BLOCKING_REQUESTING, payload }
}

export const editSettingsGlobalBlockingSuccess=(payload)=>{
    return { type:constants.EDIT_SETTINGS_GLOBAL_BLOCKING_SUCCESS, payload }
}

export const editSettingsGlobalBlockingFailed=(error)=>{
    return { type:constants.EDIT_SETTINGS_GLOBAL_BLOCKING_FAILED, error }
}
