import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {ActivateUserAccountFailed, ActivateUserAccountSuccess} from "./actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createActivateUserAccountSaga(payload_data) {
    const {payload} = payload_data;
    try {
        const response = yield call(_adminService.activateAccount, payload,payload.user_id);
        yield put(ActivateUserAccountSuccess(response));
        yield call(Notify.success,`Le compte a été ${(payload?.is_active===1)?"désactivé":"activé"} avec succès`)
        yield put(hideModal());
        yield call([window.location, window.location.reload]);
    } catch (error) {
        yield put(ActivateUserAccountFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* activateUserAccountSaga() {
    yield takeEvery(Actions.ACTIVATE_USER_ACCOUNT_REQUESTING, createActivateUserAccountSaga);
}
