import * as constants from "./constants";

export const  initAdminPasswordRequest =(payload)=> {
    return { type: constants.INIT_ADMIN_PASSWORD_REQUESTING, payload }
}

export const initAdminPasswordSuccess=(payload)=>{
    return { type:constants.INIT_ADMIN_PASSWORD_SUCCESS, payload }
}

export const initAdminPasswordFailed=(error)=>{
    return { type:constants.INIT_ADMIN_PASSWORD_FAILED, error }
}
