import * as constants from "./constants";

export const  attachOrDetachRoleRequest =(payload)=> {
  return { type: constants.ATTACH_OR_DETACH_ROLE_REQUESTING, payload }
}

export const attachOrDetachRoleSuccess=(payload)=>{
  return { type:constants.ATTACH_OR_DETACH_ROLE_SUCCESS, payload }
}

export const attachOrDetachRoleFailed=(error)=>{
  return { type:constants.ATTACH_OR_DETACH_ROLE_FAILED, error }
}
