export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_ADD_ADMIN_MODAL = 'SHOW_ADD_ADMIN_MODAL'
export const SHOW_ADD_SALESMAN_MODAL = 'SHOW_ADD_SALESMAN_MODAL'
export const SHOW_ADD_MASTER_MODAL = 'SHOW_ADD_MASTER_MODAL'
export const SHOW_DETAIL_METRICS_MODAL = 'SHOW_DETAIL_METRICS_MODAL'
export const SHOW_BOX_DETAIL_MODAL = 'SHOW_BOX_DETAIL_MODAL'
export const SHOW_INIT_SUPPLY_MODAL = 'SHOW_INIT_SUPPLY_MODAL'
export const SHOW_INIT_SUPPLY_CONFIRMED_MODAL = 'SHOW_INIT_SUPPLY_CONFIRMED_MODAL'
export const SHOW_SUPPLY_CONFIRMED_MODAL = 'SHOW_SUPPLY_CONFIRMED_MODAL'
export const SHOW_BLOCKED_OR_UNBLOCKED_CARD_MODAL = 'SHOW_BLOCKED_OR_UNBLOCKED_CARD_MODAL'
export const SHOW_ORDER_PHYSICAL_CARD_ACTION_MODAL = 'SHOW_ORDER_PHYSICAL_CARD_ACTION_MODAL'
export const SHOW_DETAIL_MOVEMENT_SAVINGS_MODAL = 'SHOW_DETAIL_MOVEMENT_SAVINGS_MODAL'
export const ACTIVATION_COMPTE_USER_MODAL = 'ACTIVATION_COMPTE_USER_MODAL'
export const SHOW_SUPPLY_DETAIL_MODAL = 'SHOW_SUPPLY_DETAIL_MODAL'
export const SHOW_CUMUL_DETAIL_MODAL = 'SHOW_CUMUL_DETAIL_MODAL'
export const SHOW_DETAIL_HISTORY_TRANSACTION_MODAL = 'SHOW_DETAIL_HISTORY_TRANSACTION_MODAL'
export const SHOW_FORGOT_PASSWORD_MODAL = 'SHOW_FORGOT_PASSWORD_MODAL'
export const SHOW_CHANGE_PASSWORD_MODAL = 'SHOW_CHANGE_PASSWORD_MODAL'
export const ATTACH_OR_DETACH_CONFIRMED_ROLE_MODAL = 'ATTACH_OR_DETACH_CONFIRMED_ROLE_MODAL'
export const SHOW_VALIDATION_COMMISSION_CONFIRMED_MODAL = 'SHOW_VALIDATION_COMMISSION_CONFIRMED_MODAL'
export const SHOW_DETAIL_ACCOUNT_LOGS_MODAL = 'SHOW_DETAIL_ACCOUNT_LOGS_MODAL'


