import * as constants from "./constants";

export const  adminListRequest =()=> {
  return { type: constants.ADMIN_LIST_REQUESTING }
}

export const adminListSuccess=(payload)=>{
  return { type:constants.ADMIN_LIST_SUCCESS, payload }
}

export const adminListFailed=(error)=>{
  return { type:constants.ADMIN_LIST_FAILED, error }
}
