import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import {validateCommissionFailed, validateCommissionSuccess} from "./actions";
import AdminService from "../../api/services/front/admin.service";
import {validateCommissionListRequest} from "../validateCommissionList/actions";
const _adminService= new AdminService()


/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */
function* createValidateCommissionSaga(payload_data) {
    const {payload} = payload_data;
    const url=`commission/validate/${payload.id}`

    try {
        const response = yield call(_adminService.updateData,url, payload);        yield put(validateCommissionSuccess(response?.data));
        yield call(Notify.success,`La commission à été ${payload.status === 'VALIDATED' ? 'validé' : 'rejeté'}`)
        yield put(validateCommissionListRequest({
            full_name:'',
            status:'',
            date_debut:'',
            date_fin:''
        },1));
        yield put(hideModal());
    } catch (error) {
        yield put(validateCommissionFailed(error));
        yield call(Notify.failure,error.message)
    }

}


export default function* ValidateCommissionSaga() {
    yield takeEvery(Actions.VALIDATE_COMMISSION_REQUESTING, createValidateCommissionSaga);
}
