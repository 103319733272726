import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import {attachOrDetachRoleFailed, attachOrDetachRoleSuccess} from "./actions";
import AdminService from "../../api/services/front/admin.service";
const _adminService= new AdminService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */
function* createAttachOrDetachRoleSaga(payload_data) {
    const {payload} = payload_data;
    let url='';
    if (payload?.type==='attach') {
        url='role/attach'
    }else{
        url='role/detach'
    }

    const send_data={"user_id":payload?.user_id}

    try {
        const response = yield call(_adminService._postData, url,send_data);
        yield put(attachOrDetachRoleSuccess(response?.data));
        yield call(Notify.success,`Le rôle commercial à été ${payload.type === 'attach' ? 'attribué avec succès' : 'supprimé avec succès'}`)
        yield put(hideModal());
        window.location.reload();
    } catch (error) {
        yield put(attachOrDetachRoleFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* AttachOrDetachRoleSaga() {
    yield takeEvery(Actions.ATTACH_OR_DETACH_ROLE_REQUESTING, createAttachOrDetachRoleSaga);
}
