import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {addSalesmanFailed, addSalesmanSuccess} from "./actions";
const _adminService= new AdminService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createSalesmanSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_adminService.createSalesman, payload);
        yield put(addSalesmanSuccess(response?.data));
        yield call(Notify.success,"Le commercial a été créé avec succès")
        yield put(hideModal());
        payload.resetForm()
    } catch (error) {
        yield put(addSalesmanFailed(error));
        yield call(Notify.failure,error.message)
    }
}


export default function* addSalesman() {
    yield takeEvery(Actions.ADD_SALESMAN_REQUESTING, createSalesmanSaga);
}
