import * as constants from "./constants";

export const  initSupplyRequest =(payload)=> {
  return { type: constants.INIT_SUPPLY_REQUESTING, payload }
}

export const initSupplySuccess=(payload)=>{
  return { type:constants.INIT_SUPPLY_SUCCESS, payload }
}

export const initSupplyFailed=(error)=>{
  return { type:constants.INIT_SUPPLY_FAILED, error }
}
