import {put, call,takeEvery} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import ApproService from "../../api/services/front/appro.service";
const _approService= new ApproService()

/**
 * ma saga login
 * @param loginPayload
 * @returns {Generator<*, void, *>}
 */
function* _supplyByWalletListSaga(payload_data) {
    const {payload} = payload_data;
    const {page} = payload_data;
    try {
        let appro_list= `appro/list/wallet/${payload.id_wallet}/?page=${page}`;

        if (payload.full_name) {
            appro_list += `&full_name=${payload.full_name}`
        }

        if (payload.status) {
            appro_list += `&status=${payload.status}`
        }

        if (payload.date_debut) {
            appro_list += `&date_debut=${payload.date_debut}`
        }

        if (payload.date_fin) {
            appro_list += `&date_fin=${payload.date_fin}`
        }

        const response = yield call(_approService._getApproList,appro_list);
        yield put(Actions.supplyByWalletListSuccess(response?.data));
    } catch (error) {
        yield put(Actions.supplyByWalletListFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}


export default function* supplyByWalletListSaga() {
    yield takeEvery(constants.SUPPLY_BY_WALLET_LIST_REQUESTING, _supplyByWalletListSaga);
}
