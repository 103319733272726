import * as constants from "./constants";

export const  historyTransactionRequest =(payload,page)=> {
  return { type: constants.HISTORY_TRANSACTION_REQUESTING,payload,page }
}

export const historyTransactionSuccess=(payload)=>{
  return { type:constants.HISTORY_TRANSACTION_SUCCESS, payload }
}

export const historyTransactionFailed=(error)=>{
  return { type:constants.HISTORY_TRANSACTION_FAILED, error }
}
