import * as constants from "./constants";

export const  validateCommissionListRequest =(payload,page)=> {
  return { type: constants.VALIDATE_COMMISSION_LIST_REQUESTING,payload,page}
}

export const validateCommissionListSuccess=(payload)=>{
  return { type:constants.VALIDATE_COMMISSION_LIST_SUCCESS, payload }
}

export const validateCommissionListFailed=(error)=>{
  return { type:constants.VALIDATE_COMMISSION_LIST_FAILED, error }
}
