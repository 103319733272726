import * as constants from "./constants";

export const initialState = {
  isLoading: false,
  data: null,
  messages: [],
  errors: null,
}

const settingsCommissionReducer = (state = initialState, action)=> {
  switch (action.type) {

    case constants.SETTINGS_COMMISSION_LIST_REQUESTING:
      return {
        ...state,
        isLoading: true,
        messages: [{ body: 'Logging in...', time: new Date() }],
      };

    case constants.SETTINGS_COMMISSION_LIST_SUCCESS:
      return {
        ...state,
        data:action.payload,
        isLoading: false,
        errors: null,
        messages: []
      };

    case constants.SETTINGS_COMMISSION_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errors:action.error,
        messages: [],
        data: null,
      };

    default:
      return state;
  }
}

export default settingsCommissionReducer
