import * as constants from "./constants";

export const  editAdminRequest =(payload)=> {
    return { type: constants.EDIT_ADMIN_REQUESTING, payload }
}

export const editAdminSuccess=(payload)=>{
    return { type:constants.EDIT_ADMIN_SUCCESS, payload }
}

export const editAdminFailed=(error)=>{
    return { type:constants.EDIT_ADMIN_FAILED, error }
}
