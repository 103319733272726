import * as constants from "./constants";

export const  transferAgentBusRequest =(payload)=> {
  return { type: constants.TRANSFER_AGENT_BUS_REQUESTING,payload }
}

export const transferAgentBusSuccess=(payload)=>{
  return { type:constants.TRANSFER_AGENT_BUS_SUCCESS, payload }
}

export const transferAgentBusFailed=(error)=>{
  return { type:constants.TRANSFER_AGENT_BUS_FAILED, error }
}
