import * as constants from "./constants";

export const  addSalesmanRequest =(payload)=> {
  return { type: constants.ADD_SALESMAN_REQUESTING, payload }
}

export const addSalesmanSuccess=(payload)=>{
  return { type:constants.ADD_SALESMAN_SUCCESS, payload }
}

export const addSalesmanFailed=(error)=>{
  return { type:constants.ADD_SALESMAN_FAILED, error }
}
