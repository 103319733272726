import * as constants from "./constants";

export const  forgotPasswordRequest =(payload)=> {
    return { type: constants.FORGOT_PASSWORD_REQUESTING, payload }
}

export const forgotPasswordSuccess=(payload)=>{
    return { type:constants.FORGOT_PASSWORD_SUCCESS, payload }
}

export const forgotPasswordFailed=(error)=>{
    return { type:constants.FORGOT_PASSWORD_FAILED, error }
}
