import * as constants from "./constants";

export const  transferTranchRequest =()=> {
  return { type: constants.TRANSFER_TRANCH_REQUESTING }
}

export const transferTranchSuccess=(payload)=>{
  return { type:constants.TRANSFER_TRANCH_SUCCESS, payload }
}

export const transferTranchFailed=(error)=>{
  return { type:constants.TRANSFER_TRANCH_FAILED, error }
}
