export default function authHeader() {
    const token = localStorage.getItem('token');
    if (token) {
        return {
            authorization: token ? `Bearer ${token}` : "",
        };
    } else {
        return {};
    }
}
