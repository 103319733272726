import * as constants from "./constants";

export const  orderPhysicalCardActionRequest =(payload)=> {
  return { type: constants.ORDER_PHYSICAL_CARD_ACTION_REQUESTING, payload }
}

export const orderPhysicalCardActionSuccess=(payload)=>{
  return { type:constants.ORDER_PHYSICAL_CARD_ACTION_SUCCESS, payload }
}

export const orderPhysicalCardActionFailed=(error)=>{
  return { type:constants.ORDER_PHYSICAL_CARD_ACTION_FAILED, error }
}
