import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import AdminService from "../../api/services/front/admin.service";
const _adminService= new AdminService()

/**
 * ma saga login
 * @param loginPayload
 * @returns {Generator<*, void, *>}
 */
function* _destockingListSaga(payload_data) {
    const {payload} = payload_data;
    const {page} = payload_data;

    const url=`destockage/list?page=${page}`

    try {
        const response = yield call(_adminService._getData,url);
        yield put(Actions.destockingListSuccess(response?.data));
    } catch (error) {
        yield put(Actions.destockingListFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}


export default function* destockingListSaga() {
    yield takeEvery(constants.DESTOCKING_LIST_REQUESTING, _destockingListSaga);
}
