/** @format */
import axios from 'axios'
import authHeader from "../../../config/token.interceptor";

//let API_URL= 'http://127.0.0.1:8000/api/v1/'

const API_URL= process.env.REACT_APP_API_URL+'/api/v1/';

class ApiService {


    _get(endpoint, params, reqOpts={headers: authHeader()}) {

      if (params) {
          reqOpts.params = params
      }

      return axios.get(`${API_URL}`+endpoint, reqOpts)
}

    _get_paginate(endpoint, params, reqOpts={headers: authHeader()}) {

        if (params) {
            reqOpts.params = params
        }

        return axios.get(endpoint, reqOpts)
    }

  _post(endpoint, body, reqOpts={headers: authHeader()}) {
    return axios.post(`${API_URL}`+endpoint, body, reqOpts)
  }

  _put(endpoint, body, reqOpts={headers: authHeader()}) {
    return axios.put(`${API_URL}`+endpoint, body, reqOpts)
  }

  _patch(endpoint, body, reqOpts={headers: authHeader()}) {
   return axios.patch( `${API_URL}`+endpoint, body, reqOpts)
  }

  _delete(endpoint, reqOpts={headers: authHeader()}) {
   return axios.delete( `${API_URL}`+endpoint, reqOpts)
  }



uploadFileWithProgress(
    endpoint,
    filePath,
    fileName,
    key,
    params,
    onProgress,
    headers = null
  ) {
    return new Promise((resolve, reject) => {
      var url = this.baseUrl + endpoint
      var formData = new FormData()
      formData.append(key, {
        type: 'image/jpeg',
        name: fileName,
        uri: filePath,
      });
      for (var k in params) {
        formData.append(k, params[k]);
      }
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'attachment; filename=' + fileName + '.png',
            Authorization: 'Bearer ' + headers,
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total;
            onProgress(percentCompleted);
          },
        })
        .then((responseJson) => {
          resolve({ statusCode: responseJson.status, body: responseJson.data })
        })
        .catch(reject)
    })
  }
}

export default ApiService
