import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import {validateDestockingFailed, validateDestockingSuccess} from "./actions";
import AdminService from "../../api/services/front/admin.service";
import {destockingListRequest} from "../destockingList/actions";

const _adminService= new AdminService()

/**
 *
 * @param payload_data
 * @returns {Generator<*, void, *>}
 */
function* createValidateDestockingSaga(payload_data) {
    const {payload} = payload_data;
    const url=`destockage/validate/${payload.id}`

    try {
        const response = yield call(_adminService.updateData,url, payload);
        yield put(validateDestockingSuccess(response?.data));
        yield call(Notify.success,`Le déstockage à été ${payload.status==='VALIDATED'?'validé':'rejeté'}`)
        yield put(destockingListRequest({
            full_name:'',
            status:'',
            date_debut:'',
            date_fin:''
        },1));
        window.location.reload();
        yield put(hideModal());
    } catch (error) {
        yield put(validateDestockingFailed(error));
        yield call(Notify.failure,error.message)
    }

}


export default function* ValidateDestockingSaga() {
    yield takeEvery(Actions.VALIDATE_DESTOCKING_REQUESTING, createValidateDestockingSaga);
}
