import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {editUserBlacklistFailed, editUserBlacklistSuccess} from "./actions";
import {userBlacklistRequest} from "../userBlacklist/actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createEditUserBlacklistSaga(payload_data) {
    const {payload} = payload_data;
    const url=`params/listenoire/${payload.id}`;
    try {
        const response = yield call(_adminService.updateData,url, payload);
        yield put(editUserBlacklistSuccess(response?.data));
        yield call(Notify.success,"L'élément a été modifié avec succès")
        yield put(hideModal());
        yield put(userBlacklistRequest( {id:1}));
        window.location.reload();
    } catch (error) {
        yield put(editUserBlacklistFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* editUserBlacklistSaga() {
    yield takeEvery(Actions.EDIT_USER_BLACKLIST_REQUESTING, createEditUserBlacklistSaga);
}
