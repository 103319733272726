import * as constants from "./constants";

export const  destockingListRequest =(payload,page)=> {
  return { type: constants.DESTOCKING_LIST_REQUESTING,payload,page}
}

export const destockingListSuccess=(payload)=>{
  return { type:constants.DESTOCKING_LIST_SUCCESS, payload }
}

export const destockingListFailed=(error)=>{
  return { type:constants.DESTOCKING_LIST_FAILED, error }
}
