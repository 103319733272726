import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {editAdminFailed, editAdminSuccess} from "./actions";

const _adminService= new AdminService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createEditAdminSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_adminService.updateAdmin, payload,payload.id);
        yield put(editAdminSuccess(response?.data));
        yield call(Notify.success,"L'utilisateur a été modifié avec succès")
        yield put(hideModal());
    } catch (error) {
        yield put(editAdminFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* editAdminSaga() {
    yield takeEvery(Actions.EDIT_ADMIN_REQUESTING, createEditAdminSaga);
}
