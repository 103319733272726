import * as constants from "./constants";

export const  transferGuichetierPdvRequest =(payload)=> {
  return { type: constants.TRANSFER_GUICHETIER_PDV_REQUESTING,payload }
}

export const transferGuichetierPdvSuccess=(payload)=>{
  return { type:constants.TRANSFER_GUICHETIER_PDV_SUCCESS, payload }
}

export const transferGuichetierPdvFailed=(error)=>{
  return { type:constants.TRANSFER_GUICHETIER_PDV_FAILED, error }
}
