import * as constants from "./constants";

export const  transferParticulierRequest =(payload)=> {
  return { type: constants.TRANSFER_PARTICULIER_REQUESTING,payload }
}

export const transferParticulierSuccess=(payload)=>{
  return { type:constants.TRANSFER_PARTICULIER_SUCCESS, payload }
}

export const transferParticulierFailed=(error)=>{
  return { type:constants.TRANSFER_PARTICULIER_FAILED, error }
}
