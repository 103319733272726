import {put, call, takeEvery} from 'redux-saga/effects';
import {addAdminFailed, addAdminSuccess} from "./actions";
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import AdminService from "../../api/services/front/admin.service";
import {adminListRequest} from "../adminList/actions";
const _adminService= new AdminService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createAdminSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_adminService.createAdmin, payload);
        yield put(addAdminSuccess(response?.data));
        yield call(Notify.success,"L'utilisateur a été ajouté avec succès")
        yield put(hideModal());
        yield(adminListRequest());
        payload.resetForm()

    } catch (error) {
        yield put(addAdminFailed(error));
        yield call(Notify.failure,error.message)
    }
}


export default function* addAdminSaga() {
    yield takeEvery(Actions.ADD_ADMIN_REQUESTING, createAdminSaga);
}
