import {all} from 'redux-saga/effects';
import authSaga from "./login/saga";
import metricsSaga from "./metrics/saga";
import addAdminSaga from "./addAdmin/saga";
import adminListSaga from "./adminList/saga";
import setEditAdminSaga from "./setEditAdmin/saga";
import approListSaga from "./approList/saga";
import InitSupplySaga from "./initSupply/saga";
import ValidateSupplySaga from "./validateSupply/saga";
import editAdminSaga from "./editAdmin/saga";
import bloackedOrdUnblockedCardSagaSaga from "./blockedOrUnblockedCard/saga";
import orderPhysicalCardActionSaga from "./orderPhysicalCardAction/saga";
import activateUserAccountSaga from "./ActivateUserAccount/saga";
import cumulTransactionListSaga from "./cumulTransactionList/saga";
import historyTransactionSaga from "./historyTransaction/saga";
import transferAgentSaga from "./transferAgent/saga";
import transferAgentBusSaga from "./transferAgentBus/saga";
import transferAgentPartSaga from "./transferAgentPart/saga";
import transferBusinessSaga from "./transferBusiness/saga";
import transferGuichetierPdvSaga from "./transferGuichetierPdv/saga";
import transferParticulierSaga from "./transferParticulier/saga";
import transferTranchSaga from "./transferTranch/saga";
import transferBusinessPartSaga from "./transferBusinessPart/saga";
import supplyByWalletListSaga from "./supplyByWalletList/saga";
import addSalesman from "./addSalesman/saga";
import editDocumentSaga from "./editDocument/saga";
import onboardUserSaga from "./onboardUser/saga";
import getDocumentSaga from "./getDocument/saga";
import settingsGlobalBlockingListSaga from "./settingsGlobalBlockingList/saga";
import editSettingsGlobalBlockingSaga from "./editSettingsGlobalBlocking/saga";
import settingsCommissionListSaga from "./settingsCommissionList/saga";
import detailSettingsCommissionSaga from "./detailSettingsCommission/saga";
import settingsEditCommissionSaga from "./settingsEditCommission/saga";
import detailSettingsItemCommissionSaga from "./detailSettingsItemCommission/saga";
import settingsItemCommissionListSaga from "./settingsItemCommissionList/saga";
import editSettingsItemCommissionSaga from "./editSettingsItemCommission/saga";
import userBlacklistSaga from "./userBlacklist/saga";
import editUserBlacklistSaga from "./editUserBlacklist/saga";
import initAdminPasswordSaga from "./initAdminPassword/saga";
import validateCommissionListSaga from "./validateCommissionList/saga";
import ValidateCommissionSaga from "./validateCommission/saga";
import ValidateDestockingSaga from "./validateDestocking/saga";
import destockingListSaga from "./destockingList/saga";
import AttachOrDetachRoleSaga from "./attachOrDetachRole/saga";
import addMaster from "./addMaster/saga";
import forgotPasswordSaga from "./forgotPassword/saga";
import settingsAccountLogsListSaga from "./settingsAccountLogsList/saga";

/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSagas() {
    yield all([
        authSaga(),
        metricsSaga(),
        addAdminSaga(),
        adminListSaga(),
        setEditAdminSaga(),
        approListSaga(),
        InitSupplySaga(),
        ValidateSupplySaga(),
        editAdminSaga(),
        bloackedOrdUnblockedCardSagaSaga(),
        orderPhysicalCardActionSaga(),
        activateUserAccountSaga(),
        cumulTransactionListSaga(),
        historyTransactionSaga(),
        transferAgentSaga(),
        transferAgentBusSaga(),
        transferAgentPartSaga(),
        transferBusinessSaga(),
        transferGuichetierPdvSaga(),
        transferParticulierSaga(),
        transferTranchSaga(),
        transferBusinessPartSaga(),
        supplyByWalletListSaga(),
        addSalesman(),
        editDocumentSaga(),
        getDocumentSaga(),
        onboardUserSaga(),
        settingsGlobalBlockingListSaga(),
        editSettingsGlobalBlockingSaga(),
        settingsCommissionListSaga(),
        detailSettingsCommissionSaga(),
        settingsItemCommissionListSaga(),
        settingsEditCommissionSaga(),
        detailSettingsItemCommissionSaga(),
        editSettingsItemCommissionSaga(),
        userBlacklistSaga(),
        editUserBlacklistSaga(),
        initAdminPasswordSaga(),
        validateCommissionListSaga(),
        ValidateCommissionSaga(),
        ValidateDestockingSaga(),
        destockingListSaga(),
        AttachOrDetachRoleSaga(),
        addMaster(),
        forgotPasswordSaga(),
        settingsAccountLogsListSaga()
    ]);

}
