import { styled } from "baseui";

export const Container = styled("div", () => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "500px",
    width: "100%",
    padding: "5%",
    position: "relative"
}));

export const Dots = styled("div", props => ({
    display: "inline-block",
    background: props.$theme.colors.primary,
    height: "5px",
    width: "5px",
    opacity: 0,
    borderRadius: "50%",
    transform: "translateX(-300px)",
    margin: "0 2px 0 2px",
    animationDuration: "4s",
    animationIterationCount: "infinite",
    animationName: {
        from: {
            transform: "translateX(0px)",
            opacity: "0.8"
        },
        to: {
            transform: "translateX(300px)",
            opacity: "0"
        }
    },
    animationDelay: props.delay && props.delay
}));