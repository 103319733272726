import {put, call,takeLatest} from 'redux-saga/effects';
import * as Actions from "./actions";
import {Notify} from "notiflix";
import * as constants from "./constants";
import TransferService from "../../api/services/front/transfer.service";
const _transferService = new TransferService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* _historyTransactionSaga(payload_data) {

    const {payload} = payload_data;
    const {page} = payload_data;

    try {

        let transfer_list= `history/filter/${payload.id_wallet}/?page=${page}`;

        if (payload.history_search) {
            transfer_list += `&history_search=${payload.history_search}`
        }

        if (payload.filter_period) {
            transfer_list += `&filter_period=${payload.filter_period}`
        }


        if (payload.filter_all_category) {
            transfer_list += `&filter_all_category=${payload.filter_all_category}`
        }

        if (payload.filter_all_start_date) {
            transfer_list += `&filter_all_start_date=${payload.filter_all_start_date}`
        }

        if (payload.filter_all_finished_date) {
            transfer_list += `&filter_all_finished_date=${payload.filter_all_finished_date}`
        }

        const response = yield call(_transferService._getTransfert,transfer_list);
        yield put(Actions.historyTransactionSuccess(response?.data));
    } catch (error) {
        yield put(Actions.historyTransactionFailed(error));
        yield call(Notify.failure,"Une erreur c'est produite.")
    }
}

export default function* historyTransactionSaga() {
    yield takeLatest(constants.HISTORY_TRANSACTION_REQUESTING, _historyTransactionSaga);
}
