import * as constants from "./constants";

export const  supplyByWalletListRequest =(payload,page)=> {
  return { type: constants.SUPPLY_BY_WALLET_LIST_REQUESTING,payload,page}
}

export const supplyByWalletListSuccess=(payload)=>{
  return { type:constants.SUPPLY_BY_WALLET_LIST_SUCCESS, payload }
}

export const supplyByWalletListFailed=(error)=>{
  return { type:constants.SUPPLY_BY_WALLET_LIST_SUCCESS, error }
}
