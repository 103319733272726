import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import {orderPhysicalCardActionFailed, orderPhysicalCardActionSuccess} from "./actions";
import CardService from "../../api/services/front/card.service";
const _cardService= new CardService()

/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createOrderPhysicalCardSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_cardService.makeOrderPhysicalCardAction, payload,payload.id_card);
        yield put(orderPhysicalCardActionSuccess(response?.data));
        yield call(Notify.success,`la commande carte ${payload.status==="LIVRE"?"a été livrée avec succès":(payload.status==="EN_COURS_LIVRAISON"?"est en cours de livraison":(payload.status==="EN_COURS_TRAITEMENT"?"est en cours de traitement":"a été annulée avec succès"))}`)
        yield put(hideModal());
    } catch (error) {
        yield put(orderPhysicalCardActionFailed(error));
        yield call(Notify.failure,error.message)
    }
}


export default function* orderPhysicalCardActionSaga() {
    yield takeEvery(Actions.ORDER_PHYSICAL_CARD_ACTION_REQUESTING, createOrderPhysicalCardSaga);
}
