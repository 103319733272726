import {put, call, takeEvery} from 'redux-saga/effects';
import * as constants from "./constants";
import {Notify} from "notiflix";
import {hideModal} from "../modal/actions";
import * as navigations  from "../../constants/navigation";
import AuthService from "../../api/services/front/auth.service";
import * as Actions from "./actions";

const _authService= new AuthService()
/**
 * ma saga login
 * @returns {Generator<*, void, *>}
 * @param payload_data
 */
function* createForgotPasswordSaga(payload_data) {
    const {payload} = payload_data;

    try {
        const response = yield call(_authService.forgotPasswordAuth, payload);
        yield put(Actions.forgotPasswordSuccess(response?.data));
        yield call(Notify.success,"Un e-mail vous a été envoyé.")
        yield put(hideModal());
        payload.redirectTo(navigations.LOGIN)
        payload.resetForm()
    } catch (error) {
        yield put(Actions.forgotPasswordFailed(error));
        yield call(Notify.failure,error.message)
    }

}

export default function* forgotPasswordSaga() {
    yield takeEvery(constants.FORGOT_PASSWORD_REQUESTING, createForgotPasswordSaga);
}
