import ApiService from "./api.service";

const authEndpoint={
    metrics: 'metrics',
}

/**
 * Api de configuration
 * @type {ApiService}
 */
const apiUrl= new ApiService()
class DashboardService {

    /**
     * Methode de recuperation des donnees
     * @param todo
     * @returns {Promise<AxiosResponse<any>>}
     */
    getMetrics(){
       return apiUrl._get(authEndpoint.metrics).then(response=>{
           return Promise.resolve(response);
       }).catch(error=>{
           return Promise.reject(error.response.data);
       })
    }

}

export default DashboardService
