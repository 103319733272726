import * as constants from "./constants";

export const  blockedOrUndblockedCardRequest =(payload)=> {
  return { type: constants.BLOCKED_OR_UNBLOCKED_CARD_REQUESTING, payload }
}

export const blockedOrUndblockedCardSuccess=(payload)=>{
  return { type:constants.BLOCKED_OR_UNBLOCKED_CARD_SUCCESS, payload }
}

export const blockedOrUndblockedCardFailed=(error)=>{
  return { type:constants.BLOCKED_OR_UNBLOCKED_CARD_FAILED, error }
}
