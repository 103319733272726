import {HIDE_PROFILE_MENU, SHOW_PROFILE_MENU} from './constants'

export const initialState = {
  isShowProfileMenu:false
}

const profileMenuReducer = (state = initialState, action)=> {
  switch (action.type) {

    case SHOW_PROFILE_MENU:
      return {
        ...state,
          isShowProfileMenu: !action.payload
      };

      case HIDE_PROFILE_MENU:
      return initialState

    default:
      return state;
  }
}

export default profileMenuReducer
