import * as constants from "./constants";

export const  editDocumentRequest =(payload,id)=> {
    return { type: constants.EDIT_DOCUMENT_REQUESTING, payload,id }
}

export const editDocumentSuccess=(payload)=>{
    return { type:constants.EDIT_DOCUMENT_SUCCESS, payload }
}

export const editDocumentFailed=(error)=>{
    return { type:constants.EDIT_DOCUMENT_FAILED, error }
}
